import logo from './logo.svg';
import './App.css';
import ChatBot from 'react-simple-chatbot';

const steps = [
  {
    id: '0',
    message: 'Welcome to zapdroid!',
    trigger: '1',
  },
  {
    id: '1',
    message: 'How are you doing today?',
    trigger: '2'
    //end: false,
  },
  {
    id: '2',
    user: true,
    end: false,
  }
];

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <ChatBot steps={steps} />
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
